export const announcements = {
    customerWelcome: 'customerWelcome',
    faciliterWelcome: 'faciliterWelcome',
    assureIncomeInvite: 'assureIncomeInvite',
    customerRatingInvite: 'customerRatingInvite',
    requestRegisterIntroduction: 'requestRegisterIntroduction',
    firstRequestAdvance: 'firstRequestAdvance',
    attorneysWeekCelebration: 'attorneysWeekCelebration',
    referralProgram: 'referralProgram',
    requestAnalysisRegistrationConfirmation: 'requestAnalysisRegistrationConfirmation',
    registerRequestWithAudio: 'registerRequestWithAudio',
    faciliterHearingWelcome: 'faciliterHearingWelcome',
    clientPremiumRequestIncentive: 'clientPremiumRequestIncentive',
    tutorialNewRequest: 'tutorialNewRequest',
    tourInitialIntroduction: 'tourInitialIntroduction',
    tourRequestDetails: 'tourRequestDetails',
    tourConcludedRequest: 'tourConcludedRequest',
    tourResolvePending: 'tourResolvePending',
    customerRepurchaseVoucher: 'customerRepurchaseVoucher',
    postPurchaseWhatsAppInvite: 'postPurchaseWhatsAppInvite',
    pendingRequestWarning: 'pendingRequestWarning',
    writeGoogleReview: 'writeGoogleReview',
    incentiveRemakeLastPurchase: 'incentiveRemakeLastPurchase',
    multiplePurchasesUpsell: 'multiplePurchasesUpsell',
    recurrenceCheckoutPopUp: 'recurrenceCheckoutPopUp',
    packageExtensionModal: 'packageExtensionModal',
    lawyerWeek: 'lawyerWeek',
    tourPetitionIndication: 'tourPetitionIndication',
    infinityUpgradeModal: 'infinityUpgradeModal',
    InfinityMarketplaceFlyout: 'InfinityMarketplaceFlyout',
    gesttoAgendaDescription: 'gesttoAgendaDescription',
    gesttoSalesFlowDescription: 'gesttoSalesFlowDescription',
    customerCorrection: 'customerCorrection',
    tourHowToEditRequest: 'tourHowToEditRequest',
};

export const localAnnouncements = {
    localMultiplePurchasesUpsell: 'localMultiplePurchasesUpsell',
    abandonedCheckoutVoucherModal: 'abandonedCheckoutVoucherModal',
    allowNotificationsModal: 'allowNotificationsModal', 
}

export default { announcements };