import moment from 'moment';

import React, { useState } from 'react';
import { isToday } from 'date-fns';
import { Button, Chip, Divider, Grid, Tooltip, Typography, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@material-ui/core';
import { Check, Edit, Computer, InsertDriveFileOutlined, LocationOn, FlashOn } from '@material-ui/icons';
import DateTimePickerField from './fields/date.time.picker.field';
import RequestAnalysisForm from './analysis/request.analysis.form';
import RequestHearingAnalysisForm from './hearing/request.hearing.analysis.form';
import RequestHearingForm from './hearing/request.hearing.form';
import CheckManualLink from '../solicitations/check.manual.link.component';
import StatusForm from './status.form.component';
import FormField from '../FormField';
import FaciliterCorrectionDropout from '../solicitations/faciliter.correction.dropout.component';
import FaciliterDropout from '../solicitations/faciliter.dropout.component';
import ButtonExtendDeadline from '../solicitations/button.action.extend.deadline.component';
import requestValidator from '../../domain/validators/request.validator';
import ConvertAnalysisNotion from './services/convert.analysis.notion.component';
import UnrevokeButton from './unrevoke.button.component';
import RequestResponsibleComponent from './request.responsible.component';
import TagsForm from '../solicitations/tags.form.component';
import RequestAllServicesDueDate from './request.all.services.due.date.component';
import RequestServicesTimeline from './services/request.services.timeline.component';
import CustomerSubscriptionInfo from '../customer/customer.subscription.info.component';
import PendingSolveButton from './pending/pending.solve.button.component';
import addressService from '../../domain/services/address.service';

import loginService from '../../domain/services/login.service';
import requestHearingAnalysisService from '../../domain/services/request/analysis/request.hearing.analysis.service';
import requestHearingService from '../../domain/services/request/request.hearing.service';
import requestAnalysisService from '../../domain/services/request.analysis.service';
import CorrectionRequestService from './services/correction.request.service.component';
import requestService from '../../domain/services/request/request.service';
import requestNotionService from '../../domain/services/request/notion/request.notion.service';

import datetimeProvider from '../../providers/datetime.provider';

import stepsSolicitation from '../../domain/constant/steps.solicitation.constant';
import RequestServiceInformationHearingDateInfo from './request.service.information.hearingDate.info';
import { requestServices } from '../../domain/constant/request.services.constant';
import RequestInstructionsReviewPetitionIA from '../solicitations/request.instructions.review.petition.ia.component';
import solicitationService from '../../domain/services/solicitation/solicitation.service';

export default function RequestServiceInformation({
    mainRequestId, setAlert, request, isEdit, isLoading, setRequest, width,
    isSmall, setIsLoading, refresh, setValue, fieldProps
}) {

    const [district, setDistrict] = useState(null);
    const [openCheckinModal, setOpenCheckinModal] = useState(false);

    const [isLoadingCheckin, setIsLoadingCheckin] = useState(false);

    const { hearingDateTime } = request;

    const handleCloseCheckinModal = () => setOpenCheckinModal(false);;

    const checkIfIsInDistrict = (city) => (request?.city === city);

    const notInDistrictMessage = () => (
        <Grid style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5rem' }}>
            <Typography style={{ fontWeight: 600 }}>Para fazer o check-in dirija-se até</Typography>
            <Typography style={{ fontSize: '1rem', fontWeight: 600, color: '#366FD0', marginLeft: '0.5rem' }}>{request.city}!</Typography>
        </Grid>
    )

    const getLocation = async () => {
        if (navigator.geolocation) {
            const location = await addressService.getCityByGeoLocation(navigator.geolocation);
            setDistrict(location?.city ?? location?.town ?? location?.borough);
            return location.city;
        }
    };


    const getSamplingInfo = (samplingInfo) => {

        if (!loginService.isInternal() || !samplingInfo || !samplingInfo.concludedAt)
            return null;

        return (
            <Grid item>
                <Typography color='textSecondary' style={{ fontWeight: 600 }}>

                    <Check style={{
                        fontSize: 18, fontWeight: 600, color: 'blue',
                        marginBottom: -3, marginRight: 5,
                    }} />

                    Serviço concluído por amostragem: {moment(samplingInfo?.concludedAt).format('DD/MM/YYYY HH:mm')}

                </Typography>
            </Grid>
        );
    }

    const getFaciliterServiceName = () => {
        const authenticatedUser = loginService.getUserAuthentication();

        const authenticatedFaciliter = (request.members || []).find(m => m.correspondentId == authenticatedUser?.faciliter?.id) || (request.faciliterReviewers || []).find(m => m.correspondentId == authenticatedUser?.faciliter?.id)
        if (authenticatedFaciliter?.faciliterServiceName)
            return authenticatedFaciliter?.faciliterServiceName

        return request.faciliterServiceName
    }

    const getServiceDueDateField = () => {

        const componentBySource = {
            faciliter: () => null,
            internal: () => (
                <Grid item xs={6}>
                    <FormField
                        {...fieldProps}
                        type='datetime'
                        name="dueDate"
                        field="Data e hora de entrega"
                        currentValue={request.dueDate}
                        onChange={(name, value) => {
                            requestService.editRequestAndFields({ id: request.id, dueDate: value })
                                .then(() => setRequest({ ...request, dueDate: value }))
                                .then(() => setAlert('Prazo atualizado com sucesso', 'success'))
                                .catch(error => setAlert(error?.response?.data?.message || 'Não foi possível editar o prazo de entrega', 'error'))
                        }}
                        required
                    />
                </Grid>
            ),
        }

        const componentFunction = componentBySource[loginService.getUserAuthentication().source];

        if (!componentFunction)
            return null;

        return componentFunction();
    }

    const servicesForms = [
        {
            key: 'hearingForm',
            validator: requestHearingService.canViewForm,
            component: () => (
                <RequestHearingForm request={request} />
            )
        },
        {
            key: 'hearingAnalysisForm',
            validator: requestHearingAnalysisService.canViewForm,
            component: () => (
                <RequestHearingAnalysisForm request={request} />
            )
        },
        {
            key: 'requestAnalysisForm',
            validator: requestAnalysisService.canViewFormAnalysis,
            component: () => (
                <RequestAnalysisForm
                    request={request} setAlert={setAlert}
                    isFaciliterPremium={request.isExternalAnalysis}
                />
            )
        },
        {
            key: 'requestNotionForm',
            validator: requestNotionService.canViewForm,
            component: () => {

                if (loginService.isCustomer())
                    return null;

                return <Button
                    variant="contained"
                    color="primary"
                    startIcon={<InsertDriveFileOutlined stle={{ fontSize: "16px" }} />}
                    style={{ pointerEvents: 'all', height: "32px", fontWeight: 600, marginRight: "10px" }}
                    onClick={() => { window.location.href = `/solicitations/${request.id}/notion` }}
                >
                    Detalhes do Parecer
                </Button>
            }
        },
    ]

    const serviceButtons = [
        {
            key: 'resolvePending',
            validator: () => request.status == stepsSolicitation.PENDING && loginService.isInternal(),
            component: () => (
                <PendingSolveButton
                    request={request} variant='contained'
                    onFinish={refresh} setAlert={setAlert}
                />
            )
        },
        {
            key: 'faciliterCorrectionDropout',
            validator: () => loginService.isFaciliter() && [stepsSolicitation.NOT_STARTED, stepsSolicitation.IN_PROGRESS, stepsSolicitation.REVIEW].includes(request.status) && request.isInCorrection,
            component: () => (
                <FaciliterCorrectionDropout request={request}
                    setAlert={setAlert} setIsLoading={setIsLoading} />
            )
        },
        {
            key: 'faciliterDropout',
            validator: () => loginService.isFaciliter() && request.status == stepsSolicitation.NOT_STARTED && !request.isInCorrection,
            component: () => (
                <FaciliterDropout request={request} setAlert={setAlert} />
            )
        },
        {
            key: 'convertAnalysisToNotion',
            validator: () => requestValidator.canConvertAnalysisToNotion(request),
            component: () => (
                <ConvertAnalysisNotion mainRequestId={mainRequestId} setAlert={setAlert}
                    isLoading={isLoading} setIsLoading={setIsLoading} />
            )
        },
        {
            key: 'unrevoke',
            validator: () => request.status == stepsSolicitation.REVOKED && !loginService.isFaciliter(),
            component: () => (
                <UnrevokeButton request={request} setAlert={setAlert} refresh={refresh} />
            )
        },
        {
            key: 'serviceCorrection',
            validator: () => requestValidator.canCorrectionService(request),
            component: () => (
                <CorrectionRequestService mainRequestId={mainRequestId} setAlert={setAlert} />
            )
        }
    ]

    const getMultipleComponents = (componentsArray = [], size) => {

        const availableItems = componentsArray.filter(item => !item.validator || item.validator(request));

        if (!availableItems.length)
            return null;

        return availableItems.map((item, index) => (
            <Grid item xs={size} key={index}>
                {item.component()}
            </ Grid>
        ))
    }

    const getServiceForms = () => {

        const availableServiceForms = getMultipleComponents(servicesForms);

        if (!availableServiceForms)
            return null;

        return (
            <>

                <Grid item>
                    <Divider />
                </Grid>

                <Grid item container spacing={4}>
                    {availableServiceForms}
                </Grid>

            </>
        )
    }

    const wrongLocationComponent = () => (
        <>
            <Grid style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5rem' }}>
                <Typography style={{ marginRight: '0.5rem', fontWeight: 600 }}>
                    {district ? 'Você está em:' : 'Localizando...'}
                </Typography>
                {district && (
                    <Typography style={{ marginTop: '-0.1rem', fontSize: '1rem', color: 'red' }}>
                        {district}
                    </Typography>
                )}
                <LocationOn style={{ marginTop: '-0.6rem', fontSize: '2rem', color: 'red' }} />
            </Grid>
            {!checkIfIsInDistrict(district) && notInDistrictMessage()}
        </>
    );

    const wrongDistrictDialog = () => (
        <Dialog open={openCheckinModal} onClose={handleCloseCheckinModal}>
            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: '1rem' }}>
                <DialogTitle>
                    Atenção! Você não está na cidade da audiência.
                </DialogTitle>
            </div>
            <DialogContent>
                <Grid container alignItems="center" justify="center" spacing={6} style={{ marginTop: 10, marginBottom: 10 }}>
                    {wrongLocationComponent()}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleCloseCheckinModal} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );

    const hearingCheckIn = async () => {

        const checkedAt = new Date();

        return requestService.editRequestAndFields({ id: request.id, checkIn: checkedAt })
            .then(() => setRequest({ ...request, checkIn: checkedAt }))
            .then(() => setAlert('Check-in realizado com sucesso!', 'success'))
            .catch(error => setAlert(error?.response?.data?.message || 'Não foi possível realizar o check-in', 'error'));
    }

    const validateCheckIn = () => {

        setIsLoadingCheckin(true);

        getLocation()
            .then((city) => {

                if (!checkIfIsInDistrict(city)) {
                    setOpenCheckinModal(true);
                    return;
                }

                return hearingCheckIn();

            })
            .finally(() => setIsLoadingCheckin(false));
    }

    const validateIfHearingIsToday = () => {
        const hearingDateTime = new Date(request.hearingDateTime);
        return isToday(hearingDateTime);
    }

    const isInPersonHearing = request.serviceId == requestServices.structure.hearingInPerson.id;

    const getLocationComponent = () => (
        <>
            <Grid item container spacing={4}>
                {request?.checkIn ? (
                    null
                ) : (
                    <Tooltip
                        title="Não é possível fazer checkin fora da data da Audiência"
                        disableHoverListener={validateIfHearingIsToday()}
                    >
                        <div>
                            <Button
                                startIcon={<LocationOn style={{ fontSize: "16px" }} />}
                                onClick={validateCheckIn}
                                variant="contained"
                                color="primary"
                                disabled={!validateIfHearingIsToday() || isLoadingCheckin}
                            >
                                Fazer Check-In
                                <CircularProgress
                                    size={20}
                                    style={{ display: isLoadingCheckin ? 'block' : 'none', marginLeft: 10 }}
                                />
                            </Button>
                        </div>
                    </Tooltip>
                )}
            </Grid>
        </>
    )

    const getCorrectionInfo = () => {

        if (!request.isInCorrection)
            return;

        const correctionDate = datetimeProvider.formatDateTime(request.lastCorrectionSendDate, 'DD [de] MMMM [de] YYYY [às] HH:mm');

        return (
            <Tooltip title={`Correção solicitada no dia ${correctionDate}`}>
                <Chip
                    label='É correção'
                    size='small'
                    icon={<Edit style={{ width: 15, color: 'white', marginTop: -3 }} />}
                    style={{
                        borderRadius: 3, fontWeight: 'bold',
                        backgroundColor: 'orange', color: 'white',
                        padding: '0 5px', marginBottom: 10, marginLeft: 4
                    }}
                />
            </Tooltip>
        );
    }

    const getExpressServiceInfo = () => {

        if (!solicitationService.isExpressService(request))
            return;

        return (
            <Tooltip title='Serviço antecipado para o mesmo dia'>
                <Chip
                    label='Serviço Express'
                    size='small'
                    icon={<FlashOn style={{ width: 15, color: 'white' }} />}
                    style={{
                        borderRadius: 3, fontWeight: 'bold',
                        backgroundColor: '#F9C31A', color: 'white',
                        padding: '0 5px', marginBottom: 10, marginLeft: 4
                    }}
                />
            </Tooltip>
        );
    }

    const getAutomaticProductionInfo = () => {

        const isInAutomaticProduction = request?.automaticProductionInfo?.enterStartProductionQueueAt && !request?.automaticProductionInfo?.failedAt
        if (!loginService.isInternal() || !isInAutomaticProduction && !request.analysisWasDevelopedByIa)
            return;

        const label = request.analysisWasDevelopedByIa ? 'Indicação desenvolvida com IA' : 'Desenvolvimento Automático'

        return (
            <Chip
                label={label}
                size='small'
                icon={<Computer style={{ width: 15, color: 'white', marginTop: -3 }} />}
                style={{
                    borderRadius: 3, backgroundColor: 'green',
                    color: 'white', padding: '0 5px',
                    marginBottom: 10, marginLeft: 4
                }}
            />
        );
    }

    const getAutomaticReviewInfo = () => {

        if (!loginService.isInternal() || !!!request?.enterAutomaticReviewFlowAt)
            return;

        const label = request?.automaticProductionInfo?.failedAt ? 'Falha na Revisão com IA' : 'Revisão com IA'
        const backgroundColor = request?.automaticProductionInfo?.failedAt ? '#c62828' : 'green'
        return (
            <Chip
                label={label}
                size='small'
                icon={<Computer style={{ width: 15, color: 'white', marginTop: -3 }} />}
                style={{
                    borderRadius: 3, backgroundColor: backgroundColor,
                    color: 'white', padding: '0 5px',
                    marginBottom: 10, marginLeft: 4
                }}
            />
        );
    }

    const renderHearingAndCheckinDate = (dateTimeInfo, isCheckin) => (
        <Grid item container alignItems='flex-end' spacing={4}>
            <RequestServiceInformationHearingDateInfo dateTimeInfo={dateTimeInfo} isCheckin={isCheckin} />
        </Grid>
    )

    return (
        <Grid item>

            {loginService.isInternal() && (
                <Grid item container direction='column' spacing={2} style={{ marginBottom: 20 }}>

                    <Grid item container>

                        {getCorrectionInfo()}

                        {getExpressServiceInfo()}

                        {getAutomaticProductionInfo()}

                        {getAutomaticReviewInfo()}

                    </Grid>

                    <Grid item>
                        <TagsForm
                            tags={request.tags}
                            requestId={request.id}
                            onSave={(field, value) =>
                                setRequest({ ...request, [field]: value })
                            }
                        />
                    </Grid>

                    <Grid item>
                        <RequestResponsibleComponent
                            request={request}
                            refresh={refresh}
                        />
                    </Grid>

                </Grid>
            )}

            <Grid container direction='column' spacing={8}>

                <Grid item container spacing={3}>

                    <Grid item xs={6} style={{ maxWidth: 300 }}>
                        <StatusForm
                            request={request}
                            onRefreshRequest={refresh}
                            setAlert={setAlert}
                            onChange={(name, field) =>
                                setRequest({ ...request, [name]: field })
                            }
                        />
                    </Grid>

                    {getMultipleComponents(serviceButtons)}

                </Grid>

                <Grid item>
                    <RequestServicesTimeline
                        request={request}
                        mainRequestId={mainRequestId}
                        isLoading={isLoading} setAlert={setAlert} />
                </Grid>

                {loginService.isInternal() && (
                    <>
                        <Grid
                            item container spacing={6} alignItems='flex-end'
                            xs={isSmall ? 12 : 8}
                        >

                            <Grid item xs={12}>
                                <RequestAllServicesDueDate
                                    mainRequestId={mainRequestId} request={request} refresh={refresh}
                                    width={width} setAlert={setAlert} fieldProps={fieldProps}
                                    setValue={setValue}
                                />
                            </Grid>

                        </Grid>


                        <Grid item>
                            <FormField
                                {...fieldProps}
                                readOnly
                                currentValue={request.customer}
                                field='Cliente'
                            />
                        </Grid>
                    </>
                )}

                <CustomerSubscriptionInfo customerId={request.customerId} />

                {getSamplingInfo(request.samplingInfo)}

                <Grid item container alignItems='flex-end' xs={isSmall ? 12 : 8} spacing={6}>

                    {getServiceDueDateField()}

                    {(loginService.isInternal() || loginService.isFaciliter()) && (
                        <Grid item xs={6}>
                            <DateTimePickerField
                                name="operationalDueDate"
                                label="Prazo de entrega do operacional"
                                value={request.operationalDueDate}
                                values={request}
                                disabled={!loginService.isInternal()}
                                setFieldValue={(name, value) => {
                                    requestService.editRequestAndFields({ id: request.id, operationalDueDate: value })
                                        .then(() => setRequest({ ...request, operationalDueDate: value }))
                                        .then(() => setAlert('Prazo atualizado com sucesso', 'success'))
                                        .catch(error => setAlert(error?.response?.data?.message || 'Não foi possível editar o prazo de entrega do operacional', 'error'))
                                }}
                                required
                                isEdit={isEdit}
                                handleInUtc={false}
                                fieldProps={fieldProps}
                                inputVariant="standard"
                                showIcon={false}
                                titleStyle={{}}
                            />
                        </Grid>
                    )}

                    {loginService.isFaciliter() && (
                        <div style={{ padding: 8 }}>
                            <ButtonExtendDeadline request={request}
                                setFieldValue={setValue} setAlert={setAlert} />
                        </div>
                    )}

                </Grid>

                <Grid item container alignItems='flex-end' spacing={4}>

                    <Grid item xs={6}>
                        <FormField
                            {...fieldProps}
                            readOnly
                            name="faciliterServiceTypeId"
                            field="Tipo do Serviço do Faciliter"
                            currentValue={getFaciliterServiceName()}

                        />
                    </Grid>

                    {loginService.isInternal() && (
                        <Grid item xs={6}>
                            <CheckManualLink
                                request={request}
                                onChange={setValue}
                                fieldProps={{ ...fieldProps, titleSize: 14 }}
                            />
                        </Grid>
                    )}

                    {(loginService.isFaciliter() && request.faciliterServiceKey == "refinePetitionIA") &&
                        <Grid item xs={12} style={{ marginTop: 20 }}>
                            <RequestInstructionsReviewPetitionIA openInDialog={true} />
                        </Grid>
                    }

                </Grid>


                {wrongDistrictDialog()}

                <Grid item spacing={4} style={{ marginLeft: 8, display: 'flex', alignItems: 'center' }}>
                    {!!hearingDateTime && renderHearingAndCheckinDate(hearingDateTime, false)}
                    {!!request.checkIn && renderHearingAndCheckinDate(request.checkIn, true)}
                    {!isInPersonHearing && !request?.checkIn ? null : getLocationComponent()}
                </Grid>
                {getServiceForms()}

            </Grid>
        </Grid >

    );
}