
import Async from "../../components/Async";

import { Mail } from "@material-ui/icons";

const ContactList = Async(() => import('../../pages/marketing/lists'));
const Campaigns = Async(() => import('../../pages/marketing/campaigns'));
const CampaignDetails = Async(() => import('../../pages/marketing/campaign.details'));
const EmailSendingCancellation = Async(() => import('../../pages/marketing/email.sending.cancellation'));

const routes = {
    emailSending: {
        id: 'E-mail',
        path: '/marketing',
        icon: <Mail />,
        children: [
            {
                id: 'campaigns',
                path: '/marketing/campaigns',
                name: 'Campanhas',
                component: Campaigns,
            },
            {
                id: 'lists',
                path: '/marketing/lists',
                name: 'Listas',
                component: ContactList,
            }
        ],
        roles: ['admin', 'email'],
        component: null,
    },
    campaignDetails: {
        id: 'campaignDetails',
        name: 'Detalhes da campanha',
        path: '/marketing/campaigns/:id',
        component: CampaignDetails,
        roles: ['admin'],
    },
}

const externalRoutes = {
    emailSendingCancellation: {
        id: 'emailSendingCancellation',
        name: 'Cancelar inscrição',
        path: '/marketing/email-sending-cancellation/:payload',
        component: EmailSendingCancellation
    },
}

const pages = Object.values(routes);

const externalPages = Object.values(externalRoutes);

export default {
    routes,
    externalRoutes,
    pages,
    externalPages
}